// extracted by mini-css-extract-plugin
export var main = "index-module--main--X-C1v";
export var input = "index-module--input--1q9Lq";
export var prose = "index-module--prose--30fmv";
export var button = "index-module--button--36ycx";
export var url = "index-module--url--1MaPq";
export var urlInput = "index-module--urlInput--1_IbX";
export var stuff = "index-module--stuff--12Jqz";
export var copy = "index-module--copy--j8O8o";
export var image = "index-module--image--i2xlR";
export var pointer = "index-module--pointer--3y6PV";
export var resultWrapper = "index-module--resultWrapper--4ytzd";
export var uiWrapper = "index-module--uiWrapper--HrkD3";
export var overflow = "index-module--overflow--Nk_xl";
export var format = "index-module--format--2aPDr";
export var textFormat = "index-module--textFormat--20bCc";
export var heading = "index-module--heading--3Wl6d";